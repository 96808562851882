import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Neon Nostalgia Bar & Lounge
			</title>
			<meta name={"description"} content={"Relive the '80s glow at Neon Nostalgia Bar, where vintage vibes meet modern nights"} />
			<meta property={"og:title"} content={"Neon Nostalgia Bar & Lounge"} />
			<meta property={"og:description"} content={"Relive the '80s glow at Neon Nostalgia Bar, where vintage vibes meet modern nights"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/logo_1.jpg?v=2023-10-24T12:33:06.409Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});